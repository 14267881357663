import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InfoIcon from '@mui/icons-material/Info';
import SyncIcon from '@mui/icons-material/Sync';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutConfirmationDialog from '../../components/LogoutConfirmationDialog.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import { toast } from 'react-toastify';
import {
  PLAN_DURATION,
  SESSION_STORAGE_CLIENT,
  SESSION_STORAGE_FIRSTNAME,
  SESSION_STORAGE_LASTNAME,
  SESSION_STORAGE_PLAN_ID
} from '../../utils/constants.js';
import { checkSessionStorageItem, clearUserInfo } from '../../utils/sessionStorage.js';
import { useQuery } from 'react-query';
import { getElligibility } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from '../../utils/date.js';

const activeChipStyles = {
  backgroundColor: '#ECFDF3',
  border: '1px solid #ABEFC6',
  borderRadius: '16px',
  color: '#067647',
  padding: '4px 10px',
  height: 'auto',
  fontWeight: '500',
  fontSize: '14px'
};

const inactiveChipStyles = {
  backgroundColor: '#F8F8F8',
  border: '1px solid ##E7E7E7',
  borderRadius: '16px',
  color: '#ACB3B5',
  padding: '4px 10px',
  height: 'auto',
  fontWeight: '500',
  fontSize: '14px'
};

const coverageTypeStyles = { fontStyle: 'italic', color: '#10182F', fontWeight: '600', ml: 1 };

export default function Profile() {
  const navigate = useNavigate();
  const [name, setName] = useState('');

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.message) {
      toast.success(location.state.message);
    }
  }, [location]);

  const {
    isLoading: isRenewElligibleLoading,
    error: elligibleError,
    data: getElligibilityData
  } = useQuery('getElligibility', getElligibility, {
    retry: true,
    refetchOnWindowFocus: true
  });

  const { plan_type, trip_length, start_date, end_date, is_active, trip_length_id } =
    getElligibilityData || {};

  const firstname = sessionStorage.getItem(SESSION_STORAGE_FIRSTNAME);
  const lastName = sessionStorage.getItem(SESSION_STORAGE_LASTNAME);

  const isShortPlan = trip_length?.toLowerCase() !== PLAN_DURATION.ANNUAL;

  useEffect(() => {
    setName(firstname + ' ' + lastName);
  }, [firstname, lastName]);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.profile.name);

  const SectionsContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
    flexWrap: 'wrap',
    marginBottom: '50px'
  });

  const SectionBox = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    backgroundColor: 'rgba(248, 248, 248, 1)',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    flexShrink: 0,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '150px',
      height: '150px'
    }
  }));

  const SectionContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  });

  const welcomeStyles = {
    fontFamily: 'Urbanist',
    fontSize: '40px',
    fontWeight: 800
  };

  const sectionNameStyles = {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px'
  };

  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleLogout = () => {
    clearUserInfo();
    navigate('/');
  };

  const handleCoverageLetterClick = () => {
    navigate('/coverage-letter');
  };

  const handleRenewClick = () => {
    const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
    const planId = sessionStorage.getItem(SESSION_STORAGE_PLAN_ID);

    const addSHortTermUrl = isShortPlan
      ? `&coverageLengthId=${trip_length_id}`
      : `&coverageLengthId=${planId}`;

    const commonUrl = `/plan-renew?planId=${planId}${addSHortTermUrl}&mode=renew`;

    const url = hasClient ? `/${client}${commonUrl}` : commonUrl;

    navigate(url);
  };

  const handleTravelInfoClick = () => {
    navigate('/travel-information');
  };

  const handleUpdateInfoClick = () => {
    const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
    const planId = sessionStorage.getItem(SESSION_STORAGE_PLAN_ID);

    const addSHortTermUrl = isShortPlan
      ? `&coverageLengthId=${trip_length_id}`
      : `&coverageLengthId=${planId}`;

    const commonUrl = `/plan-update?planId=${planId}${addSHortTermUrl}&mode=update&isActive=${is_active}`;

    const url = hasClient ? `/${client}${commonUrl}` : commonUrl;

    navigate(url, { state: { client } });
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const sections = [
    {
      name: 'Coverage Letter',
      icon: <DescriptionIcon style={{ fontSize: 60 }} />,
      onClick: handleCoverageLetterClick,
      canBeDisabled: false
    },
    {
      name: 'Renew Global Travel Plus',
      icon: (
        <AutoAwesomeIcon
          style={{
            fontSize: 60,
            color: !getElligibilityData?.renewal.eligible ? '#B9B9B9' : 'rgba(250, 180, 0, 1)'
          }}
        />
      ),
      onClick: handleRenewClick,
      canBeDisabled: !getElligibilityData?.renewal.eligible,
      disabledTextMessage: getElligibilityData?.renewal.errors?.[0]
    },
    {
      name: 'Travel Information',
      icon: <InfoIcon style={{ fontSize: 60, color: 'rgba(54, 127, 208, 1)' }} />,
      onClick: handleTravelInfoClick,
      canBeDisabled: false
    },
    {
      name: 'Update Your Information',
      icon: (
        <SyncIcon
          style={{
            fontSize: 60,
            color: !getElligibilityData?.update.eligible ? '#B9B9B9' : 'rgba(92, 181, 162, 1)'
          }}
        />
      ),
      onClick: handleUpdateInfoClick,
      canBeDisabled: !getElligibilityData?.update.eligible,
      disabledTextMessage: getElligibilityData?.update.errors?.[0]
    },
    {
      name: 'Log out',
      icon: <LogoutIcon style={{ fontSize: 60, color: 'rgba(221, 46, 68, 1)' }} />,
      onClick: handleLogoutClick,
      canBeDisabled: false,
      desktopOnly: true
    }
  ];

  return (
    <Fragment>
      {(isRenewElligibleLoading || isRenewElligibleLoading) && <PageLoader />}
      {!isRenewElligibleLoading && (
        <Container maxWidth="xl">
          <Grid container pt={12}>
            <Grid item md={12}>
              <Typography fontWeight={500} fontSize={20} fontFamily={'Inter'}>
                Welcome
              </Typography>
              <Typography sx={welcomeStyles} paragraph={true}>
                {name}
              </Typography>
              <Grid mb={1.5}>
                {is_active ? (
                  <Chip label={'Active'} sx={activeChipStyles} />
                ) : (
                  <Chip label={'Inactive'} sx={inactiveChipStyles} />
                )}

                <Typography variant="body" sx={coverageTypeStyles}>
                  {plan_type} Coverage ({trip_length}):{' '}
                  {moment(start_date).format(MOMENT_DATE_FORMAT)} to{' '}
                  {moment(end_date).format(MOMENT_DATE_FORMAT)}
                </Typography>
              </Grid>
              <Typography variant="body" paragraph={true} width={{ xs: '100%', md: '60%' }}>
                You can update your personal information, renew Global Travel Plus, and access
                travel information using the options below. Please note that any membership
                enrollment, update, or renewal will only be confirmed once your payment has been
                successfully processed.
              </Typography>
            </Grid>
            <Grid item md={12} mt={5}>
              <SectionsContainer>
                {sections.map((section, index) => (
                  <Box
                    sx={{
                      ...(section.desktopOnly ? { display: { xs: 'none', md: 'block' } } : {})
                    }}
                    key={index}
                    width={{ xs: '150px', md: '200px' }}
                    onClick={section.canBeDisabled ? null : section.onClick}>
                    <SectionBox>
                      <SectionContent
                        sx={{
                          ...(section.canBeDisabled ? { opacity: 0.5, pointerEvents: 'none' } : {})
                        }}>
                        <Typography>{section.icon}</Typography>
                      </SectionContent>
                    </SectionBox>
                    <Typography mt={2} sx={sectionNameStyles}>
                      {section.name}
                    </Typography>
                    <Typography variant="smallText" fontWeight={500} color={'#1A191E80'}>
                      {section?.disabledTextMessage}
                    </Typography>
                  </Box>
                ))}
              </SectionsContainer>
              <LogoutConfirmationDialog
                open={openLogoutDialog}
                handleClose={handleCloseLogoutDialog}
                handleLogout={handleLogout}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
