import { Grid, Typography } from '@mui/material';
import React from 'react';

function Offer({ img, body }) {
  const textStyles = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    mt: 3
  };

  return (
    <Grid py={{ md: 5, xs: 3 }} px={{ md: 5, xs: 0 }}>
      <img src={img} alt="" />

      <Typography paragraph={true} sx={textStyles} dangerouslySetInnerHTML={{ __html: body }} />
    </Grid>
  );
}

export default Offer;
