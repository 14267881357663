import { PLAN_TYPES } from '../../utils/constants';

export const LABEL_MAP = (label) => ({
  //step1
  title: 'Title',
  firstName: 'First Name',
  middleName: 'MI',
  mi: 'MI',
  lastName: 'Last Name',
  suffix: 'Suffix',
  professionalSuffix: 'Professional Suffix',
  phoneNumber: 'Phone Number',
  dateOfBirth: 'Date of Birth',
  address: 'Address',
  address2: 'Address 2',
  city: 'City',
  country: 'Country',
  state: 'State',
  zipCode: 'Zip Code',
  email: 'Email Address',
  permanentLegalResidence: 'Permanent Legal Residence',
  //step2
  //Insurance Information
  healthInsuranceProvider: 'Health Insurance Provider',
  healthInsuranceMemberId: 'Health Insurance Member ID',

  emergencyContactName: 'Emergency Contact Name',
  emergencyContactRelationship: 'Emergency Contact Relationship',
  emergencyContactPhoneNumber: 'Emergency Contact Phone Number',
  //step3
  plan: 'Plan',
  planDuration: 'Plan Duration',
  coverageLength: 'Coverage Length',
  effectiveDate: 'Effective Date',
  coveragePeriod: 'Coverage Period',
  spouseFirstName: 'First Name',
  spouseLastName: 'Last Name',
  spouseMi: 'MI',
  spouseBirthdate: 'Birth Date',
  expatriatePlan: 'Expatriate Plan',
  travelLocation: 'Travel Location',
  travelDateFrom: 'Travel Date From',
  travelDateTo: 'Travel Date To',
  petPlan: 'Pet Plan',
  children: {
    firstName: 'First Name',
    middleName: 'MI',
    mi: 'MI',
    lastName: 'Last Name',
    birthdate: 'Birth Date',
    dateOfBirth: 'Date of Birth'
  },
  pets: {
    type: 'Type',
    breed: 'Breed',
    age: 'Age',
    name: 'Name',
    gender: 'Gender'
  },
  customLabel: label
});

export const TITLE_MAP = {
  pets: 'Pet',
  children: 'Child'
};

export const CHILDREN = {
  firstName: '',
  lastName: '',
  mi: '',
  birthdate: ''
};

export const STEP1_PROPERTIES = [
  'title',
  'firstName',
  'middleName',
  'lastName',
  'suffix',
  'professionalSuffix',
  'permanentLegalResidence',
  'customLabel',
  'phoneNumber',
  'dateOfBirth',
  'address',
  'address2',
  'city',
  'country',
  'state',
  'zipCode',
  'email',
  'confirmEmailAddress'
];

export const STEP2_PROPERTIES = [
  'healthInsuranceProvider',
  'healthInsuranceMemberId',
  'emergencyContactName',
  'emergencyContactRelationship',
  'emergencyContactPhoneNumber'
];

export const STEP3_PROPERTIES = (planType) => {
  if (planType?.toLowerCase() === PLAN_TYPES.SINGLE)
    return [
      'plan',
      'expatriatePlan',
      'travelLocation',
      'travelDateFrom',
      'travelDateTo',
      'petPlan',
      'pets',
      'effectiveDate',
      'planDuration',
      'coverageLength'
    ];
  if (planType?.toLowerCase() === PLAN_TYPES.DUAL)
    return [
      'plan',
      'secondParticipantFirstName',
      'secondParticipantLastName',
      'secondParticipantMi',
      'secondParticipantBirth',
      'children',
      'expatriatePlan',
      'travelLocation',
      'travelDateFrom',
      'travelDateTo',
      'petPlan',
      'pets',
      'effectiveDate',
      'planDuration',
      'coverageLength'
    ];
  if (planType?.toLowerCase() === PLAN_TYPES.FAMILY)
    return [
      'plan',
      'spouseFirstName',
      'spouseLastName',
      'spouseMi',
      'spouseBirthdate',
      'children',
      'expatriatePlan',
      'travelLocation',
      'travelDateFrom',
      'travelDateTo',
      'petPlan',
      'pets',
      'effectiveDate',
      'planDuration',
      'coverageLength'
    ];
};

export const STEP4_PROPERTIES = ['address', 'address2', 'city', 'country', 'state', 'zipCode'];
