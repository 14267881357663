import { Grid } from '@mui/material';
import React from 'react';
import Plan from '../../../components/Plan';
import MixedPlanCard from '../../../components/MixedPlanCard';

export default function PlansGrid({ list }) {
  const hasMixedLengthPlans = list.some((item, index, arr) => {
    if (index === 0) {
      return false;
    }

    return item.has_all_terms_coverage !== arr[index - 1].has_all_terms_coverage;
  });

  const hasShortLengthPlans = list.slice(0).every((item) => item.has_short_term_coverage === true);
  const hasAnnualLengthPlans = list.slice(0).every((item) => item.has_annual_coverage === true);

  const hasOnlyAnnualPlans = !hasShortLengthPlans && hasAnnualLengthPlans && !hasMixedLengthPlans;

  return (
    <Grid container gap={3} align="center" justifyContent="center">
      {list?.length > 0 &&
        list?.map((plan, index) => {
          const { id, plan: planName, text, price } = plan;

          return (
            <Grid item xl={3.5} sm={8} key={plan.id} mb={2}>
              {!hasOnlyAnnualPlans ? (
                <MixedPlanCard
                  key={index}
                  id={id}
                  name={planName}
                  description={text}
                  durations={plan.durations}
                  price={price}
                  hideStartButton={false}
                  hoverEffect={true}
                  // onStartPlanClick={(id) => onPlanClick(id)}
                />
              ) : (
                <Plan
                  id={plan.id}
                  name={planName}
                  description={text}
                  price={plan?.durations[0]?.price}
                  hideStartButton={false}
                  hoverEffect={true}
                  // onStartPlanClick={() => onPlanClick(id)}
                />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}
