import { Box, Typography, Divider, Button, Grid } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { checkSessionStorageItem } from '../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../utils/constants';

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hoverEffect' && prop !== 'isDesktop'
})(({ hoverEffect, isDesktop }) => ({
  ...(hoverEffect && {
    '&:hover': {
      backgroundColor: '#1C354F',
      color: '#fff',
      '& button': {
        backgroundColor: '#fff',
        color: '#1C354F'
      },
      '& p': {
        color: '#fff'
      }
    }
  }),
  backgroundColor: 'white',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: isDesktop ? '85%' : 'auto'
}));

function Plan({
  id,
  name,
  description,
  price,
  hideStartButton,
  hoverEffect = false,
  onStartPlanClick
}) {
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const coverageLengthUrl = `&coverageLengthId=${id}`;

  const planLink = hasClient
    ? `/${client}/plan?planId=${id}${coverageLengthUrl}`
    : `/plan?planId=${id}${coverageLengthUrl}`;

  return (
    <CustomBox
      border={1}
      borderColor="#E5EAF1"
      pt={5}
      pb={5}
      align="center"
      hoverEffect={hoverEffect}
      isDesktop={isDesktop}>
      <Grid flex="1">
        <Typography
          sx={{
            fontFamily: 'Urbanist',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '24px',
            mb: 2
          }}>
          {name} Plan
        </Typography>
        <Typography variant="body" paragraph={true} textAlign={'center'}>
          Annual
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '48px',
            fontWeight: '700',
            lineHeight: '60px',
            mb: 2,
            mt: 2
          }}>
          {price}
        </Typography>
        <Divider />
        <Typography
          variant="body"
          pl={3}
          pr={3}
          pt={2}
          paragraph={true}
          sx={isDesktop ? { minHeight: 150 } : undefined}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Grid>
      <Grid display={'flex'} justifyContent={'center'} alignItems={'flex-end'}>
        <Button
          variant="contained"
          size="large"
          sx={{
            color: '#fff',
            textTransform: 'none',
            mt: '15px',
            display: hideStartButton ? 'none' : 'block',
            width: { lg: '200px', xs: '150px' },
            alignSelf: 'center'
          }}
          href={planLink}
          onClick={onStartPlanClick}>
          Start Plan
        </Button>
      </Grid>
    </CustomBox>
  );
}

export default Plan;
