import axios from 'axios';
import {
  SESSION_STORAGE_FIRSTNAME,
  SESSION_STORAGE_INITIALS,
  SESSION_STORAGE_LASTNAME,
  SESSION_STORAGE_PLAN_ID
} from '../../utils/constants';

export const getLoginDetails = async () => {
  const response = await axios.get('/login-page');

  return response?.data;
};

export const getForgotPasswordDetails = async () => {
  const response = await axios.get('/forgot-password-page');

  return response?.data;
};

export const getCoverageLetter = async () => {
  const response = await axios.get('coverage-letter', { requiresAuth: true });

  return response?.data;
};

export const getTravelInformation = async () => {
  const response = await axios.get('travel-info', { requiresAuth: true });

  return response?.data;
};

export const getCoverageLetterPDF = async (id) => {
  const response = await axios.get(`policy/${id}/pdf`, {
    responseType: 'blob',
    requiresAuth: true
  });

  const blob = new Blob([response.data], { type: response.headers['content-type'] });

  return blob;
};

export const validateEmail = async (email) => {
  try {
    const response = await axios.get(`customer/validate/${email}`, {
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH,
        password: process.env.REACT_APP_BASIC_AUTH_PASS
      }
    });

    if (response?.status === 200) {
      return { valid: true, data: response.data };
    } else if (response?.status === 400) {
      return { valid: false, error: 'Invalid email address' };
    } else {
      return { valid: false, error: `Unexpected status code: ${response?.status}` };
    }
  } catch (error) {
    return { valid: false, error: error.message };
  }
};

export const generateOTP = async (body) => {
  try {
    const response = await axios.post(`generate-otp`, body, {
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH,
        password: process.env.REACT_APP_BASIC_AUTH_PASS
      }
    });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const login = async (body) => {
  try {
    const response = await axios.post(`login`, body, {
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH,
        password: process.env.REACT_APP_BASIC_AUTH_PASS
      }
    });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const forgotPassword = async (body) => {
  try {
    const response = await axios.post(`forgot-password`, body);

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const validatePasswordToken = async (token) => {
  try {
    const response = await axios.get(`validate-forgot-password-token`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response?.status === 200) {
      return { valid: true, data: response.data };
    } else if (response?.status === 400) {
      return { valid: false, error: 'Invalid email address' };
    } else {
      return { valid: false, error: `Unexpected status code: ${response?.status}` };
    }
  } catch (error) {
    return { valid: false, error: error.message };
  }
};

export const updatePassword = async (body) => {
  try {
    const response = await axios.post(`update-password`, body, { requiresAuth: true });

    return response?.data;
  } catch (error) {
    throw error.response;
  }
};

export const getElligibility = async () => {
  const response = await axios.get(`customer_profile`, { requiresAuth: true });

  sessionStorage.setItem(SESSION_STORAGE_FIRSTNAME, response?.data.firstname);
  sessionStorage.setItem(SESSION_STORAGE_LASTNAME, response?.data.lastname);
  sessionStorage.setItem(SESSION_STORAGE_INITIALS, response?.data.initials);

  response?.data?.plan_id &&
    sessionStorage.setItem(SESSION_STORAGE_PLAN_ID, response?.data?.plan_id);

  window.dispatchEvent(new Event('storage'));

  return response?.data;
};
