import { PLAN_TYPES } from '../../../../utils/constants';
import { validationSchema3 } from './step3.scheme';

const generateInitialValues = (plan, planDuration, coverageLength, prefill) => {
  let initialValues;

  if (prefill) {
    initialValues = prefill;
  } else {
    initialValues = {
      plan: plan,
      coverageLength: coverageLength,
      planDuration: planDuration,
      effectiveDate: '',
      spouseFirstName: '',
      spouseLastName: '',
      spouseMi: '',
      spouseBirthdate: '',
      children: [
        {
          firstName: '',
          lastName: '',
          mi: '',
          birthdate: ''
        }
      ],
      expatriatePlan: '',
      travelLocation: '',
      travelDateFrom: '',
      travelDateTo: '',
      petPlan: '',
      pets: []
    };
  }

  if (plan.name?.toLowerCase() === PLAN_TYPES.SINGLE) {
    delete initialValues.spouseFirstName;
    delete initialValues.spouseLastName;
    delete initialValues.spouseMi;
    delete initialValues.spouseBirthdate;
    delete initialValues.children;
  } else if (plan.name?.toLowerCase() === PLAN_TYPES.DUAL) {
    // initialValues.spouseFirstName = ''; // Rename spouse fields if needed
    // initialValues.spouseLastName = '';
    // initialValues.spouseMi = '';
    // initialValues.spouseBirthdate = '';
    delete initialValues.children;
  }

  return initialValues;
};

const step3FormikConfig = (plan, planDuration, coverageLength, prefill, enableReinitialize) => {
  return {
    initialValues: generateInitialValues(plan, planDuration, coverageLength, prefill),
    validationSchema: validationSchema3, // Use the appropriate validation schema
    onSubmit: async () => {},
    enableReinitialize: enableReinitialize
  };
};

export default step3FormikConfig;
