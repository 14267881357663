import { Box, Typography, Divider, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { checkSessionStorageItem } from '../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../utils/constants';

const purchaseBtnStyles = {
  color: '#fff',
  textTransform: 'none',
  mt: '15px',
  alignSelf: 'center',
  backgroundColor: '#FFAA33',
  width: '100%',
  '&:hover': {
    backgroundColor: '#FFAA33'
  },
  '&.Mui-disabled': {
    backgroundColor: '#D7E8E4 !important'
  }
};

const planTextStyles = {
  fontFamily: 'Urbanist',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '24px',
  mb: 2,
  color: 'white'
};

const topCardContainerStyles = {
  backgroundColor: '#5CB5A2',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px'
};

const tooltipContainerStyles = {
  display: 'flex',
  alignSelf: 'flex-end',
  alignItems: 'flex-end',
  justifyContent: 'flex-end'
};

const tooltipTitleStyles = {
  fontFamily: 'Urbanist',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '24px',
  color: '#0F172A'
};

function MixedPlanCard({
  id,
  name,
  description,
  price,
  durations,
  hoverEffect = false,
  onStartPlanClick
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);

  const CustomBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'hoverEffect' && prop !== 'isDesktop'
  })(({ hoverEffect, isDesktop }) => ({
    ...(hoverEffect && {}),
    backgroundColor: 'white',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: isDesktop ? '100%' : 'auto'
  }));

  const handleClose = () => setTooltipOpen(false);
  const handleOpen = () => setTooltipOpen(!isTooltipOpen);

  const CustomButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'hovered'
  })(({ selected, hovered }) => ({
    backgroundColor: '#5BB5A20D',
    borderRadius: '8px',
    border: hovered || selected ? '1px solid #5CB5A2' : '1px solid #E9EBEF',
    marginBottom: '8px',
    color: '#1C354F',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: '800',
    transition: 'background-color 0.3s ease',
    padding: '7px 0px 7px 0px',
    '&:hover': {
      border: hovered || selected ? '1px solid #5CB5A2' : '1px solid #E9EBEF',
      backgroundColor: '#5BB5A20D'
    }
  }));

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      placement="bottom-end"
      {...props}
      classes={{ popper: className }}
      open={isTooltipOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      disableHoverListener
      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              padding: 10
            }
          },
          {
            name: 'flip',
            options: {
              enabled: true,
              behavior: ['top', 'bottom']
            }
          },
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          }
        ]
      }}
    />
  ))(() => ({
    borderRadius: '8px',
    backgroundColor: '#F7FBFA',
    marginLeft: '8px',
    boxShadow: '0px 17px 61px 0px rgba(0, 0, 0, 0.1)',
    width: 'auto',
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#F7FBFA',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }));

  const handleSelect = (id) => {
    setSelectedId(id === selectedId ? null : id);
  };

  const handleHover = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const selectedPlan = durations?.find((plan) => plan.id === (selectedId || hoveredId));

  const selectedPlanDisplayPrice = selectedPlan
    ? selectedPlan.price
    : durations?.[0]?.price || price;

  const selectedPlanDisplayLength = selectedPlan ? ` / ${selectedPlan?.trip_length}` : '';

  const coverageLengthUrl = `&coverageLengthId=${selectedPlan?.id}`;

  const planLink = hasClient
    ? `/${client}/plan?planId=${id}${coverageLengthUrl}`
    : `/plan?planId=${id}${coverageLengthUrl}`;

  return (
    <CustomBox
      border={1}
      borderColor="#E5EAF1"
      align="center"
      hoverEffect={hoverEffect}
      isDesktop={isDesktop}>
      <Grid sx={topCardContainerStyles}>
        <Grid flex="1" justifyContent={'flex-end'} alignItems={'flex-end'} display={'flex'} pt={2}>
          <CustomTooltip
            title={
              <Grid sx={{ backgroundColor: '#F7FBFA', paddingLeft: 2, paddingRight: 2 }}>
                <Grid style={tooltipContainerStyles}>
                  <img
                    src="/images/assets/ic-tooltip-close.svg"
                    alt="more info icon"
                    width={16}
                    height={16}
                    onClick={handleClose}
                    loading="lazy"
                  />
                </Grid>
                <Grid mb={1} mt={1}>
                  <Typography sx={tooltipTitleStyles}>{name} Plan</Typography>
                  <Typography
                    variant="body"
                    paragraph={true}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Grid>
              </Grid>
            }>
            <img
              src="/images/assets/ic-plan-tooltip.svg"
              alt="more info icon"
              width={24}
              height={24}
              style={{ marginRight: 16 }}
              onClick={handleOpen}
              loading="lazy"
            />
          </CustomTooltip>
        </Grid>
        <Typography sx={planTextStyles} pt={1}>
          {name} Plan
        </Typography>
        <Grid container alignItems={'center'} justifyContent={'center'} pb={2}>
          <Typography variant="body" textAlign={'center'} color={'white'} mr={0.5}>
            {selectedId || hoveredId ? '' : 'From'}
          </Typography>
          <Typography
            variant="heading4"
            fontWeight={800}
            textAlign={'center'}
            color={'white'}
            mr={0.5}>
            {selectedPlanDisplayPrice}
          </Typography>
          <Typography variant="subtitle2" fontWeight={400} textAlign={'center'} color={'white'}>
            {selectedPlanDisplayLength}
          </Typography>
        </Grid>
        <Divider />
      </Grid>
      <Grid container px={3} pt={5} flexDirection={'column'} sx={{ backgroundColor: 'white' }}>
        {durations?.map(({ trip_length, id }, index) => (
          <CustomButton
            key={index}
            variant="outlined"
            selected={selectedId === id}
            hovered={hoveredId === id}
            onClick={() => handleSelect(id)}
            onMouseEnter={() => handleHover(id)}
            onMouseLeave={handleMouseLeave}>
            {trip_length}
          </CustomButton>
        ))}
      </Grid>
      <Grid px={3} pt={3} display={'flex'} justifyContent={'center'} alignItems={'flex-end'} pb={5}>
        <Button
          variant="contained"
          size="large"
          sx={purchaseBtnStyles}
          disabled={!selectedPlan}
          href={planLink}
          // onClick={() => onStartPlanClick(id)}
        >
          Purchase Plan
        </Button>
      </Grid>
    </CustomBox>
  );
}

export default MixedPlanCard;
