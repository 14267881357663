import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { COLORS } from '../../../../utils/colors';
import { TextInput } from '../../../../components/form/TextField';
import 'react-phone-input-material-ui/lib/style.css';
import { Select } from '../../../../components/form/Select';

import { DateInput } from '../../../../components/form/DateInput';
import { ActionButtonsContainer, ApplyButtonStyled, SummaryContainer } from '../../Wizard.styles';
import SummaryDetailedItem from '../../components/SummaryDetailedItem';
import _ from 'lodash';
import { ReactComponent as DeleteIcon } from '../../../../assets/ic-close.svg';
import { ReactComponent as DiscountIcon } from '../../../../assets/ic-coupon.svg';
import { LABEL_MAP, TITLE_MAP } from '../../constants';
import { useTheme } from '@emotion/react';
import SummaryStep from '../../components/SummaryStep';
import {
  CURRENCY,
  PLAN_RENEW_MODE,
  PLAN_TYPES,
  PLAN_UPADTE_MODE
} from '../../../../utils/constants';
import { useAcceptJs } from 'react-acceptjs';
import { useQuery, useQueryClient } from 'react-query';
import {
  discountCodeValidator,
  getPaymentKeys,
  getPolicyRenewalSummary,
  getPriceSummary,
  getUpdatePriceSummary,
  submitPolicyRenewal,
  submitWizard,
  updateUpdateUserWizardInfo
} from '../../wizard.api';
import { validationSchema4 } from './step4.scheme';
import { extractIds } from '../../../../utils/wizard';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../../../components/CustomAlert';
import AuthorizeDisplay from './components/AuthorizeDisplay';
import { GOOGLE_ANALYTICS_LABELS, trackPageview } from '../../../../utils/analytics';
import useTrackScrollDepth from '../../../../hooks/useTrackScrollDepth';
import NoPayment from '../../components/NoPayment';

import Loader from '../../../../components/Loader';
import * as Sentry from '@sentry/react';

const Step4 = ({
  formik,
  onPreviousClick,
  formValues,
  handleEditStep,
  customLabel = '',
  countries,
  mode,
  numberOfPetsBought
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isTabletAndSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const [stepError, setStepError] = useState('');
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);

  const [alertContent, setAlertContent] = useState({
    severity: '',
    title: '',
    body: ''
  });

  const isUserInUpdateMode = mode === PLAN_UPADTE_MODE;
  const isUserInRenewMode = mode === PLAN_RENEW_MODE;

  const hasError = !_.isEmpty(formik.errors);

  const { data: paymentData } = useQuery(['getPaymentKeys'], () => getPaymentKeys());

  const queryClient = useQueryClient();

  const {
    data: discountCodeValidatorData,
    isLoading: discountCodeValidatorIsLoading,
    isFetching: discountCodeValidatorIsFetching,
    refetch: refetchDiscountCodeValidator,
    error: discountError
  } = useQuery(
    ['discountCodeValidator', formik.values.discountCode],
    () => discountCodeValidator(formik.values.discountCode),
    {
      enabled: false,
      retry: false
    }
  );

  const {
    data: priceSummaryData,
    isLoading: priceSummaryIsLoading,
    isFetching: priceSummaryIsFetching,
    refetch: refetchGetPriceSummary
  } = useQuery(
    [
      'getPriceSummary',
      discountCodeValidatorData,
      formValues.step3.plan?.id,
      formValues.step3.petPlan?.id,
      formValues.step3.expatriatePlan?.id
    ],
    () => {
      const payload = {
        planId: formValues.step3.plan.id,
        expatId: formValues.step3.expatriatePlan.id ?? null,
        petId: formValues.step3.petPlan.id ?? null,
        couponCode: discountCodeValidatorData?.id ?? null,
        tripLength: formValues.step3.planDuration.isShort ? formValues.step3.coverageLength?.id : ''
      };

      return getPriceSummary(payload);
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const {
    data: policyRenewalSummaryData,
    isLoading: policyRenewalSummaryIsLoading,
    isFetching: policyRenewalSummaryIsFetching,
    refetch: refetchGetPolicyRenewalSummary
  } = useQuery(
    [
      'getPolicyRenewalSummary',
      discountCodeValidatorData,
      formValues.step3.plan?.id,
      formValues.step3.petPlan?.id,
      formValues.step3.expatriatePlan?.id
    ],
    () => {
      const payload = {
        planId: formValues.step3.plan.id,
        expatId: formValues.step3.expatriatePlan.id ?? null,
        petId: formValues.step3.petPlan.id ?? null,
        couponCode: discountCodeValidatorData?.id ?? null,
        tripLength: formValues.step3.planDuration.isShort ? formValues.step3.coverageLength?.id : ''
      };

      return getPolicyRenewalSummary(payload);
    },
    {
      enabled: false,
      staleTime: 600000,
      cacheTime: 3600000
    }
  );

  const {
    data: getUpdatePriceSummaryData,
    isLoading: getUpdatePriceSummaryIsLoading,
    isFetching: getUpdatePriceSummaryIsFetching,
    refetch: refetchGetUpdatePriceSummary
  } = useQuery(
    [
      'getUpdatePriceSummary',
      discountCodeValidatorData,
      formValues.step3.plan?.id,
      formValues.step3.petPlan?.id,
      formValues.step3.expatriatePlan?.id
    ],
    () => {
      const payload = {
        expatId: formValues.step3.expatriatePlan.id ?? null,
        petId: formValues.step3.petPlan.id ?? null,
        tripLength: formValues.step3.planDuration.isShort ? formValues.step3.coverageLength?.id : ''
      };

      return getUpdatePriceSummary(payload);
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    trackPageview(GOOGLE_ANALYTICS_LABELS.wizardStep4);
  }, []);

  useEffect(() => {
    if (isUserInUpdateMode) refetchGetUpdatePriceSummary();
    if (isUserInRenewMode) refetchGetPolicyRenewalSummary();
  }, [
    isUserInUpdateMode,
    isUserInRenewMode,
    refetchGetPolicyRenewalSummary,
    refetchGetUpdatePriceSummary
  ]);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.wizardStep4.name);

  const authData = {
    apiLoginID: paymentData?.api_login_id,
    clientKey: paymentData?.public_client_key
  };

  const environment = process.env.REACT_APP_PAYMENT_ENV;

  const { dispatchData, loading, error } = useAcceptJs({ environment, authData });

  React.useEffect(() => {
    Sentry.captureException('error in useAcceptJs', {
      extra: {
        errorName: 'library error',
        errorMessage: error,
        userEmail: formValues.step1?.email
      }
    });
  }, [error]);

  const isDualPlan = PLAN_TYPES.DUAL === formValues.step3.plan.name?.toLowerCase();

  const handleSubmitPayment = async () => {
    setPaymentLoader(true);
    const isShortTerm = formValues.step3.planDuration.isShort;

    const step1 = extractIds(formValues.step1);
    const step2 = extractIds(formValues.step2);
    const step3 = extractIds(formValues.step3);

    // If the user is only saving call the api and set billingInfo=[] and opaqueData=[].

    let payload = {
      ...step1,
      ...step2,
      ...step3,
      billingInfo: [],
      country: formValues.step1.country?.id,
      opaqueData: [],
      agreeToConditions: formik.values.agreeToConditions,
      trip_length: formValues.step3.coverageLength?.id,
      policyEffectiveDate: formValues.step3?.effectiveDate
    };

    if (!isShortTerm) {
      delete payload.trip_length;
      delete payload.policyEffectiveDate;
      delete payload.coverageLength;
      delete payload.planDuration;
    }

    if (isDualPlan) {
      payload.secondParticipantFirstName = formValues.step3.spouseFirstName;
      payload.secondParticipantLastName = formValues.step3.spouseLastName;
      payload.secondParticipantMi = formValues.step3.spouseMi;
      payload.secondParticipantBirth = formValues.step3.spouseBirthdate;
      delete payload.spouseFirstName;
      delete payload.spouseLastName;
      delete payload.spouseMi;
      delete payload.spouseBirthdate;
    }

    if (isUserInUpdateMode && getUpdatePriceSummaryData?.amount <= 0) {
      try {
        setPaymentLoader(true);

        await updateUpdateUserWizardInfo(payload);

        setPaymentLoader(false);
        queryClient.refetchQueries('getElligibility');

        return navigate(`/profile`);
      } catch (error) {
        setPaymentLoader(false);
        setIsAlertOpened(true);

        setAlertContent({
          severity: 'error',
          title: 'Payment Failed',
          body: 'Your payment has failed, please try again!'
        });

        Sentry.captureException('error in payment - user Updating (only saving - no extra fees)', {
          extra: {
            errorName: 'BE/BO error',
            errorMessage: error,
            userEmail: formValues.step1?.email
          }
        });
      }
    }

    if (formik.isValid && formik.dirty) {
      setIsAlertOpened(false);
      setStepError(false);

      const date = formik.values.expirationDate.split('/');

      const cardData = {
        cardNumber: _.replace(formik.values.cardNumber, /\s/g, ''),
        cardCode: `${formik.values.securityCode}`,
        month: date[0],
        year: date[2]
      };

      let authorizeResponse;

      try {
        authorizeResponse = await dispatchData({ cardData });
      } catch (error) {
        setStepError(true);

        setAlertContent({
          severity: 'error',
          title: 'Payment Error',
          body: 'Your payment was declined. Please check your card details and try again.'
        });

        Sentry.captureException('error in payment encryption 1', {
          extra: {
            errorName: 'library error - level of sending card data 1',
            errorMessage: error?.messages?.message || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        Sentry.captureException('error in payment encryption 2', {
          extra: {
            errorName: 'library error - level of sending card data',
            errorMessage: error?.messages?.message?.[0] || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        Sentry.captureException('error in payment encryption 3', {
          extra: {
            errorName: 'library error - level of sending card data',
            errorMessage:
              JSON.stringify(error?.messages?.message) || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        Sentry.captureException('error in payment encryption 4', {
          extra: {
            errorName: 'library error - level of sending card data',
            errorMessage: JSON.stringify(error) || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        Sentry.captureException('error in payment encryption 5', {
          extra: {
            errorName: 'library error - level of sending card data',
            errorMessage: JSON.stringify(error?.messages) || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        Sentry.captureException('error in payment encryption 6', {
          extra: {
            errorName: 'library error - level of sending card data',
            errorMessage:
              JSON.stringify(error?.messages?.message?.[0]) || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        Sentry.captureException('error in payment encryption 7', {
          extra: {
            errorName: 'library error - level of sending card data',
            errorMessage:
              JSON.stringify(error?.messages?.message?.join('')) || `No message available ${error}`,
            userEmail: formValues.step1?.email
          }
        });

        setIsAlertOpened(true);
        setPaymentLoader(false);

        return;
      }

      const billlingAddress = _.pick(formik.values, [
        'address',
        'city',
        'country',
        'state',
        'zipCode'
      ]);

      payload.billingInfo = [{ ...billlingAddress, country: formik.values.country.name }];
      payload.opaqueData = [authorizeResponse?.opaqueData];
      if (!isUserInUpdateMode && !isUserInRenewMode)
        payload.coupon_code = discountCodeValidatorData?.id ?? '';

      try {
        setPaymentLoader(true);
        let res;

        if (isUserInUpdateMode) {
          res = await updateUpdateUserWizardInfo(payload);
        }

        if (isUserInRenewMode) {
          res = await submitPolicyRenewal(payload);
        }

        if (isUserInRenewMode || isUserInUpdateMode) queryClient.refetchQueries('getElligibility');

        if (!isUserInUpdateMode && !isUserInRenewMode) res = await submitWizard(payload);

        setPaymentLoader(false);

        isUserInRenewMode || isUserInUpdateMode
          ? navigate(`/coverage-letter`)
          : navigate(`/welcome/${res?.token}`);
      } catch (error) {
        setPaymentLoader(false);
        setIsAlertOpened(true);

        setAlertContent({
          severity: 'error',
          title: 'Payment Failed',
          body: 'Your payment has failed, please try again!'
        });

        Sentry.captureException('error in payment', {
          extra: {
            errorName: 'BE/BO error',
            errorMessage: error,
            userEmail: formValues.step1?.email
          }
        });
      }
    } else {
      setPaymentLoader(false);

      const touchedFields = Object.keys(validationSchema4.fields).reduce((acc, key) => {
        acc[key] = true;

        return acc;
      }, {});

      formik.setTouched(touchedFields);
      setStepError(true);
      setPaymentLoader(false);
      window.scrollTo(0, 130);

      Sentry.captureException('error in step 4 form', {
        extra: {
          errorName: 'User didnt fill all required fields',
          errorMessage: formik?.errors,
          userEmail: formValues.step1?.email
        }
      });
    }
  };

  const handleDeleteCoupon = () => {
    formik.setFieldValue('discountCode', '');
    refetchDiscountCodeValidator();
    refetchGetPriceSummary();
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleCountryChange = (event) => {
    const countrySelected = countries.find((country) => country.tid === event.target.value);

    const country = { name: countrySelected.name, id: countrySelected.tid };

    formik.setFieldValue('country', country);
    formik.setFieldValue('state', '');
  };

  const handleCreditCardNumber = (event) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    let formattedNumber = '';

    for (let i = 0; i < inputVal.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedNumber += ' ';
      }

      formattedNumber += inputVal[i];
    }

    formik.setFieldValue('cardNumber', formattedNumber);
  };

  const renderBillingAddressInformation = () => (
    <Grid>
      <Typography variant="body" fontWeight={700} color={COLORS.black}>
        Billing Address Information
      </Typography>

      <Grid mt={2}>
        <TextInput
          htmlFor="address"
          name="address"
          label="Address"
          title="address"
          variant="filled"
          onChange={formik.handleChange}
          value={formik.values.address}
          error={formik.touched?.address && Boolean(formik.errors?.address)}
          helperText={formik.touched?.address && formik.errors?.address}
          maxLength={60}
        />
      </Grid>

      <Grid mt={2}>
        <TextInput
          fullWidth
          label="City"
          id="city"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched?.city && Boolean(formik.errors?.city)}
          helperText={formik.touched?.city && formik.errors?.city}
        />
      </Grid>
      <Grid container mt={2} display={'flex'} flexDirection={'row'} gap={1}>
        <Grid item flex={1}>
          <Select
            label="Country"
            title="Country"
            variant="filled"
            name="country"
            id="country"
            defaultValue=""
            fullWidth
            disableUnderline
            onBlur={formik.handleBlur}
            onChange={handleCountryChange}
            value={formik.values.country.id || ''}
            error={formik?.touched?.country && Boolean(formik?.errors?.country?.name)}
            helperText={formik?.touched?.country && formik?.errors?.country?.name}
            renderValue={(selected) => {
              const selectedCountry = countries?.find((country) => country.tid === selected);

              return <div>{selectedCountry ? `${selectedCountry?.name} ` : ''}</div>;
            }}>
            {countries?.map(({ name, tid }, index) => (
              <MenuItem key={index} value={tid}>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item flex={1}>
          <TextInput
            fullWidth
            label="State"
            id="state"
            name="state"
            onBlur={formik.handleBlur}
            value={formik.values.state}
            onChange={formik.handleChange}
            error={Boolean(formik.errors?.state)}
            helperText={formik.touched?.state && formik?.errors?.state}
          />
        </Grid>
        <Grid item flex={1}>
          <TextInput
            fullWidth
            label="Zip Code"
            id="zipCode"
            name="zipCode"
            onBlur={formik.handleBlur}
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            error={Boolean(formik.errors?.zipCode)}
            helperText={formik.touched?.zipCode && formik?.errors?.zipCode}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderCreditCardInformation = () => (
    <Grid mb={3} mt={5}>
      <Typography variant="body" fontWeight={700} color={COLORS.black}>
        Credit/Debit Card Information
      </Typography>
      <Grid item mt={2} flexDirection={'row'} display={'flex'} gap={1}>
        <Grid item flex={1}>
          <TextInput
            fullWidth
            label="Name on Card"
            id="nameOnCard"
            name="nameOnCard"
            value={formik.values.nameOnCard}
            onChange={formik.handleChange}
            error={formik?.touched?.nameOnCard && Boolean(formik?.errors?.nameOnCard)}
            helperText={formik?.touched?.nameOnCard && formik?.errors?.nameOnCard}
          />
        </Grid>
        <Grid item flex={1}>
          <TextInput
            fullWidth
            label="Card Number"
            id="cardNumber"
            name="cardNumber"
            onBlur={formik.handleBlur}
            value={formik.values.cardNumber}
            onChange={handleCreditCardNumber}
            error={Boolean(formik?.errors?.cardNumber)}
            helperText={formik?.touched?.cardNumber && formik?.errors?.cardNumber}
            inputProps={{ type: 'tel', min: 0, maxLength: 23 }}
          />
        </Grid>
      </Grid>
      <Grid item mt={2} flexDirection={'row'} display={'flex'} gap={1}>
        <Grid item flex={1}>
          <DateInput
            views={['year', 'month']}
            fullWidth
            label="Expiration Date"
            id="expirationDate"
            name="expirationDate"
            format="MM/YY"
            value={formik.values.expirationDate}
            onChange={(date) => formik.setFieldValue('expirationDate', date)}
            error={formik?.touched?.expirationDate && Boolean(formik?.errors?.expirationDate)}
            helperText={formik?.touched?.expirationDate && formik?.errors?.expirationDate}
            disablePast
          />
        </Grid>
        <Grid item flex={1}>
          <TextInput
            fullWidth
            label="Security Code"
            id="securityCode"
            name="securityCode"
            onBlur={formik.handleBlur}
            value={formik.values.securityCode}
            onChange={formik.handleChange}
            error={Boolean(formik?.errors?.securityCode)}
            helperText={formik?.touched?.securityCode && formik?.errors?.securityCode}
            inputProps={{ type: 'tel', min: 0, maxLength: 4 }}
          />
        </Grid>
      </Grid>
      {!isUserInUpdateMode && !isUserInRenewMode && (
        <Grid mt={2} flexDirection={'row'} display={'flex'} gap={2} alignItems={'space-between'}>
          <Grid item flex={1}>
            <TextInput
              fullWidth
              label="Discount Code"
              id="discountCode"
              name="discountCode"
              onBlur={formik.handleBlur}
              value={formik.values.discountCode}
              onChange={formik.handleChange}
              error={Boolean(formik?.errors?.discountCode)}
              helperText={formik?.touched?.discountCode && formik?.errors?.discountCode}
            />
            {discountError && (
              <FormHelperText>
                {discountError?.data?.error || discountError?.data?.message}
              </FormHelperText>
            )}
            {discountCodeValidatorData && formik.values.discountCode && (
              <Chip
                label={formik.values.discountCode}
                onDelete={handleDeleteCoupon}
                avatar={<DiscountIcon />}
                deleteIcon={<DeleteIcon />}
                sx={{ mt: 2.3 }}
              />
            )}
          </Grid>
          <Grid item>
            <ApplyButtonStyled
              variant="contained"
              type="submit"
              disabled={
                discountCodeValidatorIsLoading ||
                discountCodeValidatorIsFetching ||
                !formik.values.discountCode.length
              }
              onClick={() => {
                refetchDiscountCodeValidator();
                refetchGetPriceSummary();
              }}
              endIcon={
                discountCodeValidatorIsLoading && <CircularProgress size={20} color="inherit" />
              }>
              Apply
            </ApplyButtonStyled>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const isPageLoading = isUserInUpdateMode
    ? getUpdatePriceSummaryIsLoading || getUpdatePriceSummaryIsFetching
    : false;

  const isSummaryTotalLoading =
    priceSummaryIsLoading ||
    priceSummaryIsFetching ||
    getUpdatePriceSummaryIsLoading ||
    getUpdatePriceSummaryIsFetching ||
    policyRenewalSummaryIsLoading ||
    policyRenewalSummaryIsFetching;

  const renderTotal = () => {
    return (
      <>
        {isSummaryTotalLoading && <Loader />}
        {!isSummaryTotalLoading && (
          <Grid mt={3}>
            {!isUserInUpdateMode && (
              <Grid display={'flex'} justifyContent={'space-between'}>
                <Typography variant="body" color={COLORS.black}>
                  {`${_.capitalize(formValues.step3.plan?.name)} Coverage Plan`}
                </Typography>
                <Typography variant="body" color={COLORS.black}>
                  {isUserInUpdateMode
                    ? `${CURRENCY}${getUpdatePriceSummaryData?.initial_price}`
                    : `${CURRENCY}${priceSummaryData?.initial_price}`}
                </Typography>
              </Grid>
            )}

            {((priceSummaryData?.expat_services || getUpdatePriceSummaryData?.expat_services) &&
              !isUserInUpdateMode) ||
            (isUserInUpdateMode && getUpdatePriceSummaryData?.expat_services) ? (
              <Grid display={'flex'} justifyContent={'space-between'} mt={3}>
                <Typography variant="body" color={COLORS.black}>
                  {`${_.startCase(formValues.step3.expatriatePlan?.name)} Extended Travel Program`}
                </Typography>
                <Typography variant="body" color={COLORS.black}>
                  {`${CURRENCY}${isUserInUpdateMode ? getUpdatePriceSummaryData?.expat_services : priceSummaryData?.expat_services}`}
                </Typography>
              </Grid>
            ) : null}

            {((priceSummaryData?.pet_services || getUpdatePriceSummaryData?.pet_services) &&
              !isUserInUpdateMode) ||
            (isUserInUpdateMode && getUpdatePriceSummaryData?.pet_services) ? (
              <Grid display={'flex'} justifyContent={'space-between'} mt={3}>
                <Typography variant="body" color={COLORS.black}>
                  {!isUserInUpdateMode
                    ? `${formValues.step3.pets?.length} Pet(s)`
                    : `${formValues.step3.pets?.length - numberOfPetsBought} Pet(s)`}
                </Typography>
                <Typography variant="body" color={COLORS.black}>
                  {`${CURRENCY}${isUserInUpdateMode ? getUpdatePriceSummaryData?.pet_services : priceSummaryData?.pet_services}`}
                </Typography>
              </Grid>
            ) : null}

            {priceSummaryData?.after_discount_code && (
              <Grid display={'flex'} justifyContent={'space-between'} mt={3}>
                <Typography variant="body" color={COLORS.black} fontWeight={600}>
                  Subtotal
                </Typography>
                <Typography
                  variant="body"
                  color={COLORS.black}
                  fontWeight={600}
                  sx={{
                    textDecoration: priceSummaryData?.after_discount_code ? 'line-through' : 'none'
                  }}>
                  {isUserInUpdateMode
                    ? `${CURRENCY}${getUpdatePriceSummaryData?.amount}`
                    : isUserInRenewMode
                      ? `${CURRENCY}${policyRenewalSummaryData?.initial_price}`
                      : `${CURRENCY}${priceSummaryData?.subtotal}`}
                </Typography>
              </Grid>
            )}

            {(priceSummaryData?.coupon_code_discount ||
              policyRenewalSummaryData?.renew_discount) && (
              <Grid mt={3} display={'flex'} justifyContent={'space-between'}>
                <Typography variant="body" color={COLORS.black}>
                  {isUserInRenewMode ? 'Discount on Renewal' : 'Discount'}
                </Typography>
                <Typography variant="body" color={COLORS.black}>
                  {!isUserInRenewMode
                    ? `${priceSummaryData?.coupon_code_discount}%`
                    : `- ${CURRENCY}${
                        policyRenewalSummaryData?.initial_price -
                        policyRenewalSummaryData?.renew_discount
                      }`}
                </Typography>
              </Grid>
            )}
            <Grid mt={3} display={'flex'} justifyContent={'space-between'}>
              <Typography variant="body" color={COLORS.black} fontWeight={600}>
                Total
              </Typography>
              <Typography variant="body" color={COLORS.black} fontWeight={600}>
                {isUserInUpdateMode
                  ? `${CURRENCY}${getUpdatePriceSummaryData?.amount}`
                  : isUserInRenewMode
                    ? `${CURRENCY}${policyRenewalSummaryData?.subtotal}`
                    : `${CURRENCY}${priceSummaryData?.after_discount_code ?? priceSummaryData?.subtotal}`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const renderStepError = () => (
    <Grid mb={3}>
      {hasError && stepError && (
        <Grid mb={0.8}>
          <Typography variant="body" fontWeight={500} color={COLORS.error}>
            Please ensure all fields are filled in before proceeding.
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  const renderCheckbox = () => (
    <Grid mt={6.8} display={'flex'}>
      <Grid mr={1.75}>
        <Checkbox
          id="agreeToConditions"
          checked={Boolean(formik.values.agreeToConditions) || false}
          value={Boolean(formik.values.agreeToConditions) || false}
          onChange={formik.handleChange}
          {...label}
          color="secondary"
          disabled={paymentLoader || loading}
          sx={{ padding: 0, paddingTop: '2px' }}
        />
      </Grid>
      <Typography variant="body" fontWeight={'500'} color={COLORS.black}>
        I confirm that I have read and accepted the{' '}
        <Link href="/terms" target="_blank" rel="noopener noreferrer" component="a">
          terms and conditions
        </Link>{' '}
        and{' '}
        <Link href="/privacy" target="_blank" rel="noopener noreferrer" component="a">
          privacy policy
        </Link>
        .
      </Typography>
    </Grid>
  );

  const step2FirstSectionPickedProperties = _.pick(formValues.step2, [
    'healthInsuranceProvider',
    'healthInsuranceMemberId'
  ]);

  const step2SecondSectionPickedProperties = _.omit(formValues.step2, [
    'healthInsuranceProvider',
    'healthInsuranceMemberId'
  ]);

  const step3ExpatSectionPickedProperties = _.pick(formValues.step3, [
    'expatriatePlan',
    'travelLocation',
    'travelDateFrom',
    'travelDateTo'
  ]);

  const step3SpousePickedProperties = _.pick(formValues.step3, [
    'spouseFirstName',
    'spouseLastName',
    'spouseMi',
    'spouseBirthdate',
    PLAN_TYPES.FAMILY == formValues.step3.plan.name?.toLowerCase() ? 'children' : ''
  ]);

  const step3PetsPickedProperties = _.pick(formValues.step3, ['petPlan', 'pets']);

  const step3PlanPickedProperties = _.pick(formValues.step3, [
    'plan',
    'planDuration',
    'coverageLength',
    'effectiveDate'
  ]);

  const mapLabelsToConstants = LABEL_MAP(customLabel);

  const renderSummaryDetailedItems = (data, parentKey) => (
    <Grid container>
      {data.map((item, index) => {
        const keyLevel1 = parentKey + '-' + index;

        return (
          <React.Fragment key={keyLevel1}>
            {Object.entries(item).map(([key, value], innerIndex) => {
              if (!mapLabelsToConstants[key] || key === 'id') return null;

              // For arrays
              if (Array.isArray(value)) {
                const arrayKey = key;

                return (
                  <React.Fragment key={key + '-' + innerIndex}>
                    {value?.length > 0 &&
                      value.map((innerItem, subIndex) => {
                        const keyLevel2 = keyLevel1 + '-' + subIndex;

                        return (
                          <React.Fragment key={keyLevel2}>
                            <Grid width={'100%'} mt={2}>
                              <Typography variant="body" color="black" fontWeight={700}>
                                {TITLE_MAP[arrayKey] + ' ' + (subIndex + 1)}
                              </Typography>
                            </Grid>
                            {Object.entries(innerItem).map(
                              ([innerKey, innerVal], subInnerIndex) => {
                                if (typeof innerVal === 'object' && innerVal?.name) {
                                  return (
                                    <SummaryDetailedItem
                                      key={keyLevel2 + '-' + innerKey + '-' + subInnerIndex}
                                      title={mapLabelsToConstants[arrayKey]?.[innerKey]}
                                      description={innerVal?.name}
                                    />
                                  );
                                }

                                // For other properties within the array
                                return (
                                  !!innerVal && (
                                    <SummaryDetailedItem
                                      key={keyLevel2 + '-' + innerKey + '-' + subInnerIndex}
                                      title={mapLabelsToConstants[arrayKey]?.[innerKey]}
                                      description={innerVal}
                                    />
                                  )
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              }

              // For objects
              if (typeof value === 'object') {
                // If the object has a 'label' property, use it as the description
                if (value.name) {
                  return (
                    <SummaryDetailedItem
                      key={keyLevel1 + '-' + key + '-' + innerIndex}
                      title={mapLabelsToConstants[key]}
                      description={_.capitalize(value.name)}
                    />
                  );
                } else {
                  return Object.entries(value).map(([innerKey, innerVal], subIndex) => {
                    if (!!innerVal && innerKey !== 'id') {
                      return (
                        <SummaryDetailedItem
                          key={keyLevel1 + '-' + innerKey + '-' + subIndex}
                          title={mapLabelsToConstants[innerKey]}
                          description={innerVal}
                        />
                      );
                    }

                    return null;
                  });
                }
              }

              // For non-object values
              return (
                !!value && (
                  <SummaryDetailedItem
                    key={keyLevel1 + '-' + key + '-' + innerIndex}
                    title={mapLabelsToConstants[key]}
                    description={value}
                  />
                )
              );
            })}
          </React.Fragment>
        );
      })}
    </Grid>
  );

  const shouldShowPaymentSections = getUpdatePriceSummaryData?.amount > 0 || !isUserInUpdateMode;

  return (
    <Container display={'flex'} maxWidth={'xl'}>
      {isPageLoading && <Loader />}
      {!isPageLoading && (
        <Grid display={'flex'} gap={2}>
          <Grid
            flex={1.4}
            mt={6}
            alignItems={!isTabletAndSmaller ? 'flex-start' : 'center'}
            justifyContent={!isTabletAndSmaller ? 'flex-start' : 'center'}
            display={!isTabletAndSmaller ? 'block' : 'flex'}
            flexDirection={'column'}>
            {!isPageLoading && (
              <Grid
                mt={{ sm: 5, xs: 4, md: 6, lg: 7, xl: 7 }}
                width={!isTabletAndSmaller ? '80%' : '60%'}>
                {getUpdatePriceSummaryData?.amount <= 0 && <NoPayment />}
                {shouldShowPaymentSections && renderStepError()}
                {shouldShowPaymentSections && renderBillingAddressInformation()}{' '}
                {shouldShowPaymentSections && renderCreditCardInformation()}{' '}
                <Grid>
                  {shouldShowPaymentSections && renderTotal()}
                  {shouldShowPaymentSections && <AuthorizeDisplay />} {renderCheckbox()}
                  <Grid mt={2}>
                    <CustomAlert
                      severity={alertContent.severity}
                      title={alertContent.title}
                      body={alertContent.body}
                      isOpened={isAlertOpened}
                      onAlertClose={() => setIsAlertOpened(false)}
                    />
                  </Grid>
                  <ActionButtonsContainer mt={7} container gap={1}>
                    <Button variant="outlined" onClick={onPreviousClick}>
                      Previous
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleSubmitPayment}
                      disabled={!formik.values.agreeToConditions || loading || paymentLoader}
                      endIcon={paymentLoader && <CircularProgress size={20} color="inherit" />}>
                      {isUserInUpdateMode
                        ? getUpdatePriceSummaryData?.amount > 0
                          ? 'Pay now'
                          : 'Save'
                        : 'Enroll Now'}
                    </Button>
                  </ActionButtonsContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!isTabletAndSmaller && (
            <SummaryContainer mt={5.9}>
              <SummaryStep
                title="Personal information"
                mt={0}
                stepIndex={0}
                handleEditStep={handleEditStep}>
                <Grid>{renderSummaryDetailedItems([formValues.step1], 'personalInfo')}</Grid>
              </SummaryStep>
              <SummaryStep
                title="Insurance Information"
                mt={2}
                stepIndex={1}
                handleEditStep={handleEditStep}>
                <>
                  <Grid>
                    {renderSummaryDetailedItems(
                      [step2FirstSectionPickedProperties],
                      'insuranceInfo'
                    )}
                  </Grid>

                  {formValues.step2.emergencyContactName !== '' && (
                    <>
                      <Grid mt={5}>
                        <Typography variant="body" color={COLORS.black} fontWeight={700}>
                          Emergency Contact Information
                        </Typography>
                      </Grid>
                      <Grid>
                        {renderSummaryDetailedItems(
                          [step2SecondSectionPickedProperties],
                          'emergencyContactInfo'
                        )}
                      </Grid>
                    </>
                  )}
                </>
              </SummaryStep>
              <SummaryStep title="Plan Type" mt={2} stepIndex={2} handleEditStep={handleEditStep}>
                <>
                  <Grid>{renderSummaryDetailedItems([step3PlanPickedProperties], 'planType')}</Grid>
                  {PLAN_TYPES.SINGLE !== formValues.step3.plan.name?.toLowerCase() && (
                    <Grid mt={5}>
                      <Typography variant="body" color={COLORS.black} fontWeight={700}>
                        {isDualPlan ? 'Second Participant' : 'Spouse'}
                      </Typography>
                      <Grid>
                        {renderSummaryDetailedItems(
                          [step3SpousePickedProperties],
                          'familyOrSecondPartcicipant'
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {formValues.step3.expatriatePlan !== '' && (
                    <>
                      <Grid mt={5}>
                        <Typography variant="body" color={COLORS.black} fontWeight={700}>
                          Expatriate Information
                        </Typography>
                      </Grid>
                      <Grid>
                        {renderSummaryDetailedItems(
                          [step3ExpatSectionPickedProperties],
                          'expatInfo'
                        )}
                      </Grid>
                    </>
                  )}
                  {formValues.step3.pets?.length > 0 && (
                    <>
                      <Grid mt={5}>
                        <Typography variant="body" color={COLORS.black} fontWeight={700}>
                          Pet Information
                        </Typography>
                      </Grid>
                      <Grid>
                        {renderSummaryDetailedItems([step3PetsPickedProperties], 'petInfo')}
                      </Grid>
                    </>
                  )}
                </>
              </SummaryStep>
            </SummaryContainer>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default Step4;
