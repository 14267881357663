import { Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Plan from '../Plan';
import { checkSessionStorageItem } from '../../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../../utils/constants';
import MixedPlanCard from '../MixedPlanCard';
import { useNavigate } from 'react-router-dom';

export default function Plans({ title, description, list, button }) {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');
  const navigate = useNavigate();
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

  const navigateTo = hasClient ? `${client}/plans` : `/plans`;

  const hasMixedLengthPlans = list.some((item, index, arr) => {
    if (index === 0) {
      return false;
    }

    return item.has_all_terms_coverage !== arr[index - 1].has_all_terms_coverage;
  });

  const hasShortLengthPlans = list.slice(0).every((item) => item.has_short_term_coverage === true);
  const hasAnnualLengthPlans = list.slice(0).every((item) => item.has_annual_coverage === true);

  const hasOnlyAnnualPlans = !hasShortLengthPlans && hasAnnualLengthPlans && !hasMixedLengthPlans;

  const onPlanClick = (id) => {
    try {
      const url = hasClient ? `/${client}/plan?planId=${id}` : `/plan?planId=${id}`;
      navigate(url);
    } catch (error) {
      // console.log({ error });
    }
  };

  return (
    <Grid
      p={{ md: 5, xs: 0 }}
      py={{ xs: 3 }}
      sx={{
        backgroundImage: 'url("/images/green-bg.svg")',
        backgroundSize: 'cover'
      }}>
      <Container align="center" maxWidth="xl">
        <Grid align="center" p={{ md: 5, xs: 0 }}>
          <Typography
            variant="heading2"
            sx={{
              mb: 1
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography variant="subtitle" dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
        <Grid container gap={3} align="center" justifyContent="center" mb={isLoggedIn ? 5 : 0}>
          {list?.length > 0 &&
            list?.map((plan) => (
              <Grid item xl={3.5} sm={8} xs={12} key={plan.id} mb={2}>
                {!hasOnlyAnnualPlans ? (
                  <MixedPlanCard
                    name={plan.plan}
                    id={plan.id}
                    description={plan.text}
                    durations={plan.durations}
                    price={plan.price}
                    // onStartPlanClick={(id) => onPlanClick(id)}
                    planLink={
                      hasClient ? `/${client}/plan?planId=${plan.id}` : `/plan?planId=${plan.id}`
                    }
                  />
                ) : (
                  <Plan
                    id={plan.id}
                    name={plan.plan}
                    description={plan.text}
                    price={plan?.durations[0]?.price}
                    hideStartButton={false}
                    hoverEffect={true}
                    // onStartPlanClick={() => onPlanClick(plan.id)}
                  />
                )}
              </Grid>
            ))}
        </Grid>
        {!isLoggedIn && (
          <Button
            variant="contained"
            href={navigateTo}
            sx={{
              color: '#fff',
              textTransform: 'none',
              my: 5
            }}>
            {button}
          </Button>
        )}
      </Container>
    </Grid>
  );
}
